import React, { useEffect, useState } from "react";
import NavMenu from "../Navbar";
import Footer from "../Footer";
import Image from "../../assets/img/brands2/Group 10.png";
import Image1 from "../../assets/img/brands2/Baseus-1-1.jpg";
import Image2 from "../../assets/img/brands2/Baseus-2-1.jpg";
import Image3 from "../../assets/img/brands2/Baseus-4-1.jpg";
import Image4 from "../../assets/img/brands2/Baseus-5-1.jpg";
import Image5 from "../../assets/img/brands2/Baseus-11-1.jpg";
import Image6 from "../../assets/img/brands2/Baseus-3-1.jpg";
import Image7 from "../../assets/img/brands2/Baseus-13-1.jpg";
import Image8 from "../../assets/img/brands2/Baseus-14-1.jpg";
import Image9 from "../../assets/img/brands2/Baseus-8-1.jpg";
import Image10 from "../../assets/img/brands2/Baseus-16-3.jpg";
import Image11 from "../../assets/img/brands2/Baseus-12-2.jpg";
import Image12 from "../../assets/img/brands2/Baseus-9-1.jpg";
import Image13 from "../../assets/img/brands2/Baseus-10-1.jpg";
import Image14 from "../../assets/img/brands2/Baseus-7-1.jpg";
import "../Brands2/L&D.css";
import "../Brands2/newbrand.css"
import RectImgL from "../../assets/img/brands2/baseus-polska_creative_2a.jpg";
import { getBrandsWithId, getProductViaCategory } from "../../api/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ColorRing, TailSpin } from "react-loader-spinner";

export default function Baseus() {
  const navigate = useNavigate();
  const [StpData, setStpData] = useState([]);
  const location = useLocation();
  // const { brandid, brandName } = location?.state;
  const {  id } = useParams();
  const [StpCategory, setStpCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [ProductId, setProductId] = useState("");
  const [clicked, setClicked] = useState(false);
  const [pageLoading, setPageLoading] = useState(true)
  const [cartValues, setCartValues] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPageLoading(false)
    }, 500);
    getBrandsById(35);
    getProductbyCategory(ProductId);
    console.log("==productId", ProductId);
    window.scrollTo(0, 0);
    console.log("-->brand armorall", ProductId);
  }, [35, ProductId]);

  const getBrandsById = (id) => {
    const url = getBrandsWithId(id);
    console.log("brandUrl", url);
    axios
      .get(url)
      .then((resp) => {
        console.log("--resp", resp.data);
        setStpData(resp?.data?.brandwise_category_data);
        setProductId(resp.data.brandwise_category_data[0].category_id);
        console.log("- - - category - - - ", resp.data.brandwise_category_data);
      })
      .catch((error) => {
        console.log("--error--", error);
      });
  };

  const getProductbyCategory = (ProductId) => {
    const url = getProductViaCategory(ProductId);

    axios
      .get(url)
      .then((resp) => {
        console.log("--resp in brand product", resp);
        setStpCategory(resp.data.data.product_data);
        // setProductId(resp.data.data.product_data[0].id)
        console.log(
          "--- - - - - setting id",
          resp.data.data.product_data
        );
      })
      .catch((error) => {
        console.log("--error", error);
      });
  };

  const changeProduct = (id) => {
    console.log("...--changeProduct", changeProduct);
    getProductbyCategory(id);
    setCategoryId(id);
  };

  const stpProductWrld = () => {
    console.log("---strproductWorld", id);
    // localStorage.clear();
    localStorage.removeItem('selectedItems');
    window.localStorage.removeItem("CategoryCheckdDataLocal");
    window.localStorage.removeItem('packCheckedDataLocal');
    window.localStorage.removeItem('categoryValue2');
    window.localStorage.removeItem('')
    localStorage.removeItem("selectedItems2");
    localStorage.removeItem("selectedItems3");
    localStorage.removeItem("selectedItems4");
    localStorage.removeItem("brandsCheckdDataLocal");
    localStorage.removeItem("ddvalue");
    localStorage.removeItem("CategoryCheckdDataLocal");
    localStorage.removeItem("bcvalue");
    localStorage.removeItem("packCheckedDataLocal");
    localStorage.removeItem("packsvalue");
    localStorage.removeItem("resbrandsCheckdDataLocal");
    localStorage.removeItem("resddvalue");
    localStorage.removeItem("resCategoryCheckdDataLocal");
    localStorage.removeItem("resbcvalue");
    localStorage.removeItem("respackCheckedDataLocal");
    localStorage.removeItem("respacksvalue");
    navigate("/BASEUS/Products", {
      state: {
        categoryById: categoryId == "" ? ProductId : categoryId,
        brandID: 35,
      },
    });
  };
  const sendProdId = (id) => {
    console.log(" ------ id", id);
    navigate(`/product/${id}`, { state: { productId: id, clicked: clicked } });
    // localStorage.clear()
  };
  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log("Modal is closed, running effect in CartPage...");
  };
  
  const imageUrls = [
    { img: Image4, heading: "CAR CHARGER" },
    { img: Image2, heading: "CAR EXTERIOR ACC" },
    { img: Image3, heading: "CABELS & INVERTER" },
    { img: Image14, heading: "MOUNT AND HOLDERS" },
    { img: Image9, heading: "	ASHTRAY" },
    { img: Image13, heading: "	TRASH CAN" },
    { img: Image5, heading: "	CARWASH SPRAY" },
    { img: Image8, heading: "SUN SHADE UMBRELLA" },
    { img: Image7, heading: "	INFLATOR" },
    { img: Image10, heading: "	VACCUM CLNR" },
  ];

  const imageUrls2 = [
    { img: Image2, heading: "Airbag Car Door Bumper Strip Transparent 4 Pcs Pack (CRFZT-A02)" },
    { img: Image6, heading: "Digital Display Dual USB 4.8A Car Charger 24W With 3-in-1 Data Cable (TZCCBX-0G)" },
    { img: Image3, heading: "in-Car inverter 150W (110V US/JP) Black (CRNBQ-01)" },
    { img: Image5, heading: "GF5 Car Wash Spray Nozzle Black With Water Pipe CPGF000101" },
  ];

  return (
    <>
      <NavMenu
        onCloseModal={handleModalClose}
        setCartBoolean={setCartValues}
        cartBoolean={cartValues}
      />
       {
        pageLoading ? (<>
          <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', background:'black'}}>
            <TailSpin  color="#ffd11b" />
          </div>
          </>):(<>
            <div className="baseus">
        {/* <div className="imageColor">
          <img src={Image} className="logoImageL" alt="L&DLogo" />
        </div> */}

        <div className="BfirstImg">
          {/* <h1 className="scienceHrL">A WORLD OF FRAGRANCES AND DESIGNS</h1>
          
            <p className='LDpText'>The exclusive natural fragrances combined with the original designs</p>
          <span className="redDiv">
            <div className="scienceImg">
              <iframe
                width="965"
                height="494"
                src="https://www.youtube.com/embed/PjKqkeNC9qI"
                className="mainImageL"
                title="LD Ingles"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </span> */}
          {/* <div className="greenTransparent"></div> */}
        </div>
        <div className="ourProducts stprk">
          <h2 className="productsh1 newBrandCat">CATEGORIES</h2>
          {/* <div className="scienceDiv">
            <p className='productp'>From fuel additives to oil treatments, functional fluids and more, STP products keep your engines performing at their best.</p>
          </div> */}
          <div className="mainArmorallCar2 ">
            {imageUrls.map((item, index) => (
              <div
                key={index}
                className="brandingImg4"
              >
                <img
                  src={item.img}
                  className={`newCatImg `}
                  alt={item.heading}
                />
                <div className="mainLDText"><p style={{ textTransform: "uppercase" }} className="helloNew">
                {item.heading.split(' ').map((word, index) => (
    (index + 1) % 3 === 0 ? (
      <>
        {word} <br />
      </>
    ) : (
      `${word} `
    )
  ))}
                </p></div>
                
              </div>
            ))}
            {/* {StpData.map((item, index) => (
              <div
                key={index}
                className="brandingImg4"
                onClick={() => changeProduct(item.category_id)}
              >
                <img
                  src={item.image_url}
                  className={`categoryImgar2 ${
                    categoryId === item.category_id ||
                    (categoryId === "" && item.category_id === ProductId)
                      ? "product--highlighted2LD"
                      : ""
                  }`}
                  alt={item.name}
                />
                <div className="mainLDText"><p style={{ textTransform: "uppercase" }} className="helloLD">
                  {item.name}
                </p></div>
                
              </div>
            ))} */}
          </div>
        </div>
        <div className="stpProducts">
          <h2 className="productsh22 newBrandHead">BASEUS PRODUCTS RANGE</h2>
          {/* <p className='productp'>For over 60 years – and more miles than we can count – STP® has been one of the most trusted names in automotive care. Our products are essential tools in your car maintenance routine.</p> */}
        <div className="NewBrandYellowCol"></div>
          
          <div className="brandImg2 temp">
            <div className="whiteColorB"></div>
            <div className="MainBrandImg">
              
{/* 
              {StpCategory && StpCategory.length > 0 ? (
                StpCategory?.map((item, index) => (
                  <a
                          href={`/product/${item?.id}`}
                          style={{ textDecoration: "none"  }}
                        >
                  <div key={index} className="brandImg4 LDimg4">
                    <img src={item.image_url} className="productImgar22LD" alt={item.title} />
                    <div className="brandName1 branded1L">
                      <p
                        className="helloImg1L"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.title}
                      </p>
                    </div>
                  </div>
                  </a>
                ))
              ) : (
                <div style={{ width: "200%" }}>
                  <p className="noProdtFound2">No products found.</p>
                </div>
              )} */}

              {imageUrls2.map((item, index) => (
                <div key={index} className="brandImg4 LDimg4" >
                  <img src={item.img} className="productImg2N" />
                  <div className="brandName1 branded1L">
                    <p className="helloNew" style={{ textTransform: 'uppercase' }}> {item.heading.split(' ').map((word, index) => (
    (index + 1) % 3 === 0 ? (
      <>
        {word} <br />
      </>
    ) : (
      `${word} `
    )
  ))}</p>
                  </div>
                </div>

              ))}
            </div>
          </div>
          <div className="viewing-more2">
            
            <button className="viewBtnN" >
            {/* <button className="viewBtnN" onClick={() => stpProductWrld()}> */}
              View All Products
            </button>
          </div>
        </div>
    
        <div className="discoverB">
          <img style={{ width: "100%" }} src={RectImgL} alt="L&DBanner"/>
          <div style={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: window.innerWidth > 580 ? '60px':'15px',
    background: 'linear-gradient(180deg, #383838 1.24%, rgba(15, 16, 46, 0) 100%)'
  }} />
        </div>

        <Footer />
      </div>

          </>)
}
     
    </>
  );
}
